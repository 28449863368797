import request from '@/utils/request';
import { AxiosPromise } from 'axios';
import { SysMessageVO, SysMessageForm, SysMessageQuery } from '@/api/system/sysMessage/types';

/**
 * 查询系统用户消息列表
 * @param query
 * @returns {*}
 */

export const listSysMessage = (query?: SysMessageQuery): AxiosPromise<SysMessageVO[]> => {
  return request({
    url: '/system/sysMessage/list',
    method: 'get',
    params: query
  });
};
/**
 * 查询系统用户消息未读数量
 * @param query
 * @returns {*}
 */

export const countReadSysMessage = (): AxiosPromise<number> => {
  return request({
    url: '/system/sysMessage/notRead',
    method: 'get'
  });
};

/**
 * 查询系统用户消息详细
 * @param messageId
 */
export const getSysMessage = (messageId: string | number): AxiosPromise<SysMessageVO> => {
  return request({
    url: '/system/sysMessage/' + messageId,
    method: 'get'
  });
};

/**
 * 新增系统用户消息
 * @param data
 */
export const addSysMessage = (data: SysMessageForm) => {
  return request({
    url: '/system/sysMessage',
    method: 'post',
    data: data
  });
};

/**
 * 修改系统用户消息
 * @param data
 */
export const updateSysMessage = (data: SysMessageForm) => {
  return request({
    url: '/system/sysMessage',
    method: 'put',
    data: data
  });
};

/**
 * 删除系统用户消息
 * @param messageId
 */
export const delSysMessage = (messageId: string | number | Array<string | number>) => {
  return request({
    url: '/system/sysMessage/' + messageId,
    method: 'delete'
  });
};

/**
 * 标记已读
 * @param messageId
 */
export const readSysMessage = (messageId: string | number | Array<string | number>) => {
  return request({
    url: '/system/sysMessage/read/' + messageId,
    method: 'post'
  });
};
