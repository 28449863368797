<template>
  <div>
    <el-popover
      v-model:visible="msgVisible"
      placement="bottom-end"
      :width="200"
      title="消息提醒">
      <div>
        <el-link :underline="true" v-if="total > 0" @click="handleMsg">您有{{ total }}条未读的消息~</el-link>
        <el-link :underline="true" v-else @click="handleMsg">暂无最新消息~</el-link>
      </div>

      <template #reference>
        <div class="el-badge el-tooltip__trigger">
          <svg class="svg-icon size-icon" aria-hidden="true" @click="handleMsg">
            <use xlink:href="#icon-message-sys" fill=""></use>
          </svg>
          <sup class="el-badge__content el-badge__content--danger is-fixed is-dot" style="margin-top: 10px;" v-if="total > 0"/>
        </div>
      </template>
    </el-popover>
  </div>
</template>

<script setup lang="ts">
import { countReadSysMessage } from "@/api/system/sysMessage";
import { getToken } from '@/utils/auth';
const { proxy } = getCurrentInstance() as ComponentInternalInstance;


const msgVisible = ref(false);
const total = ref<number>();

/**
 * 消息点击事件
 */
const handleMsg = () => {
  const url = '/system/sysMessage';
  proxy?.$tab.openPage(url).then(() => {
    // 执行结束的逻辑
    const obj = { path: url, name: "SysMessage" };
    proxy?.$tab.refreshPage(obj);
  })
}

/** 查询系统用户消息列表 */
const getNotReadTotal = async () => {
  const res = await countReadSysMessage();
  const isRead = res.data > 0;
  total.value = res.data;
  msgVisible.value = isRead;
}

const url = import.meta.env.VITE_APP_WEB_SOCKET_URL+'/resource/websocket?clientid='+import.meta.env.VITE_APP_CLIENT_ID+'&Authorization=Bearer ' + getToken();
const websocket = ref<WebSocket>(new WebSocket(url));
onMounted(() => {
  openSocket();
  getNotReadTotal();
});

/** * 连接SOCKET */
const openSocket = ()=> {
  websocket.value.addEventListener('open', (event) => {
    console.log('WebSocket opened:', event);
    startHeartbeat();
    // 如果不是空的就清理掉
    if (reconnectTimeout) {
      clearTimeout(reconnectTimeout.value);
    }
  });

  websocket.value.addEventListener('message', (event) => {
    // Update the component state with the received message
    // const jsonObject = JSON.parse(event.data);
    console.log('接收到服务器消息',event.data)
    // 转换为json对象
    const data = JSON.parse(event.data);
    if (data.type === 'SYS_MSG_ALERT') { // 系统消息提醒
      getNotReadTotal();
    }else if (data.type === 'LIVE_ADMIN') { // 直播间消息
      // 查看是否加载了这个直播间管理界面，并且方法是否存在
      if (window.liveAdminMethods && window.liveAdminMethods.msgDispose) {
        window.liveAdminMethods.msgDispose(data.message);
      } else {
        console.log('LiveAdmin component method is not available.');
      }
    }

  });

  websocket.value.addEventListener('close', (event) => {
    console.log('WebSocket closed:', event);
    clearInterval(heartbeatInterval.value); // 停止心跳
    // 尝试重新连接
    reconnectTimeout.value = setTimeout(openSocket, 5000); // 5 秒后重连
  });

  websocket.value.addEventListener('error', (event) => {
    console.error('WebSocket error:', event);
  });
}

const heartbeatInterval = ref();
const reconnectTimeout = ref();

const startHeartbeat = ()=> {
  // 每隔30秒发送一个心跳消息
  heartbeatInterval.value = setInterval(() => {
    if (websocket && websocket.value.readyState === WebSocket.OPEN) {
      console.log('心跳包发送')
      websocket.value.send('ping');
    }
  }, 10000);
}

onBeforeUnmount(() => {
  // 组件卸载时清理资源
  if (websocket) {
    websocket.value.close();
  }
  clearInterval(heartbeatInterval.value); // 清理心跳定时器
  clearTimeout(reconnectTimeout.value); // 清理重连定时器
});

</script>

<style lang="scss" scoped>

</style>
